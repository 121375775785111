import React from 'react';
import { HashRouter as Router, Link } from "react-router-dom";

function Footer(props) {
  if(props.isMobile) {
    return (
      <footer id="footer" className="footer-mobile-wrapper">
      <div className="footer-bar">
        <div className='footer-title'>Codeblock Lending Inc.</div>
        <div className='one-row'>
          <svg className="svgIcon1">
            <use xlinkHref="#iconmail1" />
          </svg>
          <span>cs@kpeso.ph</span>
        </div>
        <div className='one-row'>
          <svg className="svgIcon2">
            <use xlinkHref="#iconzizhi1" />
          </svg>
          <span>Company Registration: NO.CS201913681</span>
        </div>
        <div className='one-row'>
          <svg className="svgIcon2">
            <use xlinkHref="#iconzizhi1" />
          </svg>
          <span>Certificate Of Authority: NO.3059</span>
        </div>
      </div>
    </footer>
    )
  }

  return (
    <footer id="footer" className="footer-wrapper">
      <div className="footer-bar">
        <div className="footer-content">
          <div className="one-item">
            <div className="item-title">Codeblock Lending Inc.</div>
            <div className="one-row">
              <svg>
                <use xlinkHref="#iconmail1" />
              </svg>
              <p>cs@kpeso.ph</p>
            </div>
            <div className="one-row">
              <svg className="iconzizhi">
                <use xlinkHref="#iconzizhi1" />
              </svg>
              <p>Company Registration: NO.CS201913681</p>
            </div>
            <div className="one-row">
              <svg className="iconzizhi">
                <use xlinkHref="#iconzizhi1" />
              </svg>
              <p>Certificate Of Authority: NO.3059</p>
            </div>
          </div>
          <div className="one-item">
            <div className="item-title mar30">Follow Us</div>
            <a target="_blank" href="https://www.facebook.com/KPeso-103905355246242" rel="noopener noreferrer">
              <svg>
                <use xlinkHref="#iconFacebook1"/>
              </svg>
              <span>Facebook</span>
            </a>
            {/* <a target="_blank" href="https://www.twitter.com/k_peso"> */}
            <a href={'#/'}>
              <svg>
                <use xlinkHref="#icontwitter1"/>
              </svg>
              <span>Twitter</span>
            </a>
            <a href={'#/'}>
              <svg>
                <use xlinkHref="#iconyoutube1"/>
              </svg>
              <span>Youtube</span>
            </a>
          </div>
          <div className="one-item">
            <div className="item-title mar30">Regulations</div>
            <Router>
              <Link to="/creditAgreement" target="_blank">
                《KPeso Loan Agreement》
              </Link>
              <Link to="/privacyAgreement" target="_blank">
                《Privacy Agreement》
              </Link>
              <Link to="/serviceAgreement" target="_blank">
                《Service Agreement》
              </Link>
            </Router>
          </div>
        </div>
      </div>
      <div className="bottom-bar">Copyright © 2020 | KPeso | All Rights Reserved</div>
    </footer>
  );
}


export default Footer;
