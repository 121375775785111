import React from 'react';
import PropTypes from 'prop-types';
// import QueueAnim from 'rc-queue-anim';
// import { Button } from 'antd';
import { Element } from 'rc-scroll-anim';


class Banner extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isMobile: PropTypes.bool,
    navToShadow: PropTypes.func,
  }
  static defaultProps = {
    className: 'banner',
  }
  constructor(props) {
    super(props)
    this.state = {
      appUrl: '',
    }
  }
  componentDidMount(){
    console.log(window.location.origin)
    // fetch('https://www.kpeso.com/api/download/page/config?hash=1',{
    fetch( window.location.origin + '/api/download/page/config?hash=1',{
      method:'GET',
      headers:{'Content-Type':'application/json;charset=UTF-8'},
      mode:'no-cors',
      cache:'default'
    })
    .then(res =>res.json())
    .then((res) => {
      if(res.ret === 0) {
        const { data={} } = res;
        this.setState({
          appUrl: data.url,
        })
      }
    }) 
  }

  render() {
    const { className, isMobile, navToShadow } = this.props;
    const { appUrl } = this.state;

    // const appUrl = 'https://kpeso.onelink.me/FkQA/app';
    if (isMobile) {
      return (
        <Element id="home" component="section" className="banner-mobile-wrapper" onChange={navToShadow}>
          <div className="banner-mobile">
            <p className="bm-title">{'A Fast And Reliable Loan In Philippine.'}</p>
            <p className="bm-subtitle">{'Max amount up to ₱20000'}</p>
            <p className="bm-subtitle">{'Loan released in 2 hours to 24 hours'}</p>
            <a className="bm-download-btn" target="_blank" href={appUrl} key="a" rel="noopener noreferrer">
              <svg>
                <use xlinkHref="#iconxiazai" />
              </svg>
              {'Download KPeso'}
            </a>
          </div>
        </Element>
      )
    }
    
    return (
      <Element id="home" component="section" className={`${className}-wrapper page`} onChange={navToShadow}>
        <div className={className}>
          <div className={`${className}-home`}>
            <p className={`${className}-home-title`}>{'A Fast And Reliable'}</p>
            <p className={`${className}-home-title`}>{'Loan In Philippine.'}</p>
            <p className={`${className}-home-subTitle`}>{'Max amount up to ₱20000'}</p>
            <p className={`${className}-home-subTitle`}>{'Loan released in 2 hours to 24 hours'}</p>
            <a className={`${className}-download-btn`} target="_blank" href={appUrl} key="a" rel="noopener noreferrer">
              <svg>
                <use xlinkHref="#iconxiazai" />
              </svg>
              {'Download KPeso'}
            </a>
          </div>
        </div>
      </Element>
    );
  }
}

export default Banner;
