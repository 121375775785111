import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row } from 'antd';
import { page2 } from './data';
import { HashRouter as Router, Link } from "react-router-dom";

export default function Page2(props) {

  const renderCard = (data) => {
    const cardHtml = data.map((card, i) => (
      <div className={props.isMobile?'one-mobile-faq':'one-faq'} key={i.toString()}>
        <img src={card.img} className="cardImg" alt='card'/>
        <div className="cardTitle">{card.title}</div>
        <div className="cardDetail">
          {
            card.descriptions.map((item, idx) => {
              return <p key={idx} >{item}</p>
            })
          }
        </div>
      </div>
    ));
    return cardHtml;
  }

  const children = renderCard(page2);

  if(props.isMobile) {
    return (
      <section id="FAQ" className="page-mobile-wrapper">
        <div className="pm2-comtent">
          <div className="pm2-title">{'FAQ'}</div>
          <p className="pm2-subtitle">{'KPeso’s team has compiled a list of common misgivings that they hope will help you.'}</p>
          <QueueAnim
            type="bottom"
            className="pm2-faqs"
            delay={100}
          >
            {children}
          </QueueAnim>
          {/* 移动端的follow us and regulations */}
          <div className="follow-regul">
            <div className="one-item">
              <div className="item-title">Follow Us</div>
              <a target="_blank" href="https://www.facebook.com/KPeso-103905355246242" rel="noopener noreferrer">
                <svg className="svgIcon1">
                  <use xlinkHref="#iconFB"/>
                </svg>
                <span>Facebook</span>
              </a>
              {/* <a target="_blank" href="https://www.twitter.com/k_peso"> */}
              <a href={'#/'}>
                <svg className="svgIcon2">
                  <use xlinkHref="#icontt"/>
                </svg>
                <span>Twitter</span>
              </a>
              <a href={'#/'}>
                <svg className="svgIcon3">
                  <use xlinkHref="#iconyb"/>
                </svg>
                <span>Youtube</span>
              </a>
            </div>
            <div className="one-item regul">
              <div className="item-title">Regulations</div>
              <Router>
                <Link to="/creditAgreement" target="_blank">
                  《KPeso Loan Agreement》
                </Link>
                <Link to="/privacyAgreement" target="_blank">
                  《Privacy Agreement》
                </Link>
                <Link to="/serviceAgreement" target="_blank">
                  《Service Agreement》
                </Link>
              </Router>
            </div>
          </div>
        </div>
      </section>
    )
  }

  return (
    <section id="FAQ" className="page-wrapper page2">
      <div className="page2-content">
        <p className="page2-title">{'FAQ'}</p>
        <p className="page2-subtitle">{'KPeso’s team has compiled a list of common misgivings that they hope will help you.'}</p>
        <QueueAnim
          component={Row}
          type="bottom"
          className="page2-faqs"
          delay={500}
        >
          {children}
        </QueueAnim>
      </div>
    </section>
  );
}
