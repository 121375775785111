import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row } from 'antd';
import { page1 } from './data';

export default function Page1(props) {
  const renderCard = (data) => {
    if(props.isMobile) {
      const cardHtml = data.map((card, i) => (
        <div className="one-item" key={i.toString()}>
          <img src={card.img} className={`cardImg${i+1}`} alt='card'/>
          <div className="cardTitle">{card.title}</div>
          <div className="cardDetail">{card.description}</div>
        </div>
      ));
      return cardHtml;
    }
    
    const cardHtml = data.map((card, i) => (
      <div className="one-feature" key={i.toString()}>
        <img src={card.img} className={`cardImg${i+1}`} alt='card'/>
        <div className="cardTitle">{card.title}</div>
        <div className="cardDetail">{card.description}</div>
      </div>
    ));
    return cardHtml;
  }

  const children = renderCard(page1);

  if(props.isMobile) {
    return (
      <section id="product" className="page-mobile-wrapper">
        <div className="pm1-comtent">
          <div className="pm1-title">{'Features'}</div>
          <QueueAnim
            // component={Row}
            type="bottom"
            className="pm1-features"
            delay={100}
          >
            {children}
          </QueueAnim>
        </div>
      </section>
    )
  }

  return (
    <section id="product" className="page-wrapper page1">
      <div className="page1-content">
        <p className="page1-title">{'Features'}</p>
        <QueueAnim
          component={Row}
          type="bottom"
          className="page1-features"
          delay={500}
        >
          {children}
        </QueueAnim>
      </div>
    </section>);
}
